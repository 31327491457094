.App {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  --header-height: 48px;
  font-size: 12px;
  --blue: #4e97fe;
  --red: #e60000;
  --green: #319331;
  --ui-font-family: "Arial" sans-serif;
}

.Header {
  background-color: #ebeced;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--header-height);

  display: flex;
  flex-direction: row;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.HeaderItem {
  display: flex;
  justify-content: center;
  align-content: left;
  flex-direction: column;
  height: 100%;
}

.RightAlign {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.ToggleSettingsMenuVisibilityButton {
  display: inline;
  border: none;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
}

.ToggleSettingsMenuVisibilityButton:hover {
  filter: contrast(0.8) brightness(1.2);
}

.HeaderOpenSettingsIcon path {
  fill: #666;
}

.HeaderCloseSettingsIcon path {
  fill: #aa000088;
}

.PyodideLoadingNotification {
  font-weight: bold;
}

.SettingsMenu {
  background-color: #ebeced;
  color: black;
  position: absolute;
  top: var(--header-height);
  right: 0;
  z-index: 1;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.Hidden.Hidden {
  display: none;
}

.SettingsMenuItem {
  font-family: var(--ui-font-family);
  font-size: 12px;
  padding: 12px;
  cursor: pointer;
  border-top: 1px solid #ccc;
}

.SettingsMenuItem:hover {
  background-color: #ebeced;
  color: black;
  filter: brightness(0.8);
}

.Code {
  font-family: monospace;
  font-size: 12px;
}

.DownloadMenuContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* flex-direction: column; */

  background-color: #00000088;
}

.DownloadMenu {
  min-width: 300px;
  width: min-content;
  height: min-content;

  background-color: #fffffe;
  padding: 16px;
  border-radius: 8px;
}

.DownloadMenuHeading {
  margin: 0;
  font-size: 24px;
  font-family: var(--ui-font-family);
  font-weight: normal;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.DownloadFileNameForm {
  margin-top: 16px;
  margin-bottom: 16px;
}

.DownloadFileNameInputContainer {
  display: block;
  padding-right: 16px;
}

.DownloadFileNameInput {
  width: 100%;
  padding: 8px;
  font-family: var(--ui-font-family);
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.DownloadMenuButtons {
  white-space: nowrap;
  text-align: right;
}

.Main {
  position: absolute;
  top: var(--header-height);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--header-height));
}

.EditorContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  zoom:120%;
}

.ConsoleContainer {
  background-color: #fffffe;
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  overflow: auto;
  zoom:120%;
}

.Console {
  padding: 4px;
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-weight: normal;
  font-size: 12px;
  font-feature-settings: "liga" 0, "calt" 0;
  font-variation-settings: normal;
  line-height: 18px;
  letter-spacing: 0px;
  white-space: pre;
}

.ConsoleText--compositionText {
  background-color: black;
  color: white;
}

.ConsoleTextSegment--stdin {
  color: var(--green);
}

.ConsoleTextSegment--stderr {
  color: var(--red);
}

.Button {
  color: white;
  background-color: var(--blue);
  font-family: var(--ui-font-family);
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;

  border: none;
  border-radius: 4px;
  padding: 8px 12px;

  cursor: pointer;

  display: inline-block;
  width: min-content;
}

.Button--green {
  background-color: var(--green);
}

.Button--red {
  background-color: var(--red);
}

.Button:hover {
  filter: contrast(0.8) brightness(1.2);
}

.Button:disabled {
  cursor: not-allowed;
}

.SmallLeftMargin {
  margin-left: 8px;
}

.SmallRightMargin {
  margin-right: 8px;
}

.ConsoleInputContainer {
  display: inline;
}

.ConsoleInput {
  outline: none;
  opacity: 0;
  caret-color: transparent;
  line-height: 1px;
  font-size: 1px;
}

.ConsoleCursor {
  background-color: black;
  border: 1px solid black;
  color: transparent;

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.ConsoleCursor--focused {
  background-color: black;
}

.ConsoleCursor--unfocused {
  background-color: transparent;
}

.todo_delete {
  outline: none;
  border-radius: 0;
  border: 1px solid black;
  width: min-content;
  min-height: 12px;
}
